import { Component, OnInit } from '@angular/core';
import { faFacebook, faInstagram, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  // Social icons
facebook = faFacebook;
instagram = faInstagram;
twitter = faTwitter;
youtube = faYoutube;
heart = faHeart;

  constructor() { }

  ngOnInit() {
  }

}
