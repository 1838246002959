import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})

export class ToolbarComponent implements OnInit {

  @Output() public sidenavToggle = new EventEmitter();

  constructor(public router: Router) { }

  ngOnInit() {
  }

  public onToggleSidenav = () => {
      this.sidenavToggle.emit();
    }

  scrollToId(id: string) {
    let element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth', block: 'start'});
  }
}
